<template>
  <div class="historical-changes">
    <div class="personal-title">
      <div class="title-bg">
        <h4>历史修改记录</h4>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>历史修改记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="historical-block">
      <div class="historical-list" v-if="historicalList.length > 0" v-for="(his, index) in historicalList" :key="his.property_id">
        <div class="list-title">
          <div><i class="sea-iconfont">&#xe61f;</i>预约届数：{{his.sea_funeral_period.period_name}}</div>
          <div><i class="sea-iconfont">&#xe640;</i>参加方向：{{his.sea_funeral_period.period_site}}</div>
          <div><i class="sea-iconfont">&#xe685;</i>修改人：{{his.create_user_name}}</div>
          <span>{{his.update_time}}</span>
        </div>
        <div class="list-source">
          <el-table
            :data="his.supervise_record_list"
            style="width: 100%">
            <el-table-column
              prop="property_name"
              label="修改字段">
            </el-table-column>
            <el-table-column
              prop="old_data"
              label="修改前">
            </el-table-column>
            <el-table-column
              prop="new_data"
              label="修改后">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <xgj-empty v-if="records === 0" title="暂无预约历史修改记录"></xgj-empty>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'historical-changes',
  data() {
    return {
      historicalList: [],
      records: null,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  created() {
    this.getFuneralRecord()
  },
  computed: {
    ...mapState({
      partnerId: state => state.user.partnerId,
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    getFuneralRecord() {
      const that = this;
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(0, 0, 0, 0.3)'
      });
      let params = {
        partnerId: that.partnerId,
        userId: that.userInfo.id
      };
      that.$api.website.getFuneralRecord(params)
        .then(dates => {
          console.log(dates)
          if (dates.length > 0) {
            this.historicalList = dates
          } else {
            this.records = 0
          }
          loading.close()
        }).catch( err => {
          loading.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./personal-view.scss";
</style>
